import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lotties/loader.json';
import css from './LoaderComponent.module.css';

function LoaderComponent(props) {
  const { className } = props;
  //Lottie animation data
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div className={className ? className : css.loaderContainer}>
      <Lottie options={defaultOptions} width={120} height={120} />
    </div>
  );
}

export default LoaderComponent;
